/*switch styles*/

div.btn-container{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

div.btn-container i{
    display: inline-block;
    position: relative;
    top: -9px;
}

label {
    font-size: 13px;
    color: #424242;
    font-weight: 500;
}

.btn-color-mode-switch{
    display: inline-block;
    margin: 0px;
    position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner{
    margin: 0px;
    width: 140px;
    height: 30px;
    background: #E0E0E0;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before{
    content: attr(data-on);
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    top: 7px;
    right: 20px;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after{
    content: attr(data-off);
    width: 70px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 26px;
    position: absolute;
    left: 2px;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #991b1b;
    padding: 5px 0px;
}

.btn-color-mode-switch > .alert{
    display: none;
    background: #FF9800;
    border: none;
    color: #fff;
}

.btn-color-mode-switch input[type="checkbox"]{
    cursor: pointer;
    width: 50px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after{
    background: #E0E0E0;
    color: #fff;
}
.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before{
    background: #E0E0E0;
    color: #020202;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after{
    content: attr(data-on);
    left: 68px;
    background: #991b1b;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before{
    content: attr(data-off);
    right: auto;
    left: 20px;
}

.btn-color-mode-switch input[type="checkbox"]:checked ~ .alert{
    display: block;
}

/*mode preview*/
.dark-preview{
    background: #0d0d0d;
}

.dark-preview div.btn-container i.fa-sun-o{
    color: #777;
}

.dark-preview div.btn-container i.fa-moon-o{
    color: #fff;
    text-shadow: 0px 0px 11px #fff;
}

.white-preview{
    background: #fff;
}

.white-preview div.btn-container i.fa-sun-o{
    color: #ffa500;
    text-shadow: 0px 0px 16px #ffa500;
}

.white-preview div.btn-container i.fa-moon-o{
    color: #777;
}

p.by {
    
}

p.by a{
    text-decoration: none;
    color: #000;
}

.dark-preview p.by a{
    color: #777;
}

.white-preview p.by a{
    color: #000;
}
