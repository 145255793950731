html, body {
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden !important;
    font-family: 'Poppins' !important;
    scrollbar-width: thin;
    scrollbar-color: #99000b #e8e8e8;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.81), rgba(161, 145, 145, 1) 40%, rgba(109, 109, 109, 0.8) 70%) center center / cover;
}

.font-josefin-sans {
    font-family: 'Poppins' !important;
}

.landingHero {
    background: url("../src/assets/LandingHeroImg.png");
    background-repeat: no-repeat;
    background-size: cover !important;
}

.accordion-body {
    color: black;
}

.accordion-button {
    color: black;
}

.custm-bg-chat {
    background: linear-gradient(40deg, #ff0013, #f9f1f1);
}

.custm-bg-head {
    background: linear-gradient(360deg, #ff9396, #ffffff);
}

.chatbackgr {
    background: url("../public/assets/img/chatbg.png");
    background-repeat: no-repeat;
    background-size: cover !important;
    scrollbar-width: none;
}

.dreamclr {
    background-color: rgb(255, 112, 122);
    border: 5px solid rgb(255, 112, 122);
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it covers all other content */
}

/* Disable interaction with the page when loading */
.page-disabled {
    /* Disable all interactions */
    opacity: 0.5;
    /* Optional: dim the content */
}

.image {
    transition: transform 0.5s, box-shadow 0.5s;
}

.imgbackgroung {
    opacity: 0.5;
    --tw-bg-opacity: 1;
}

.hidden.md\:block.comingsoon.relative.font-josefin-sans {
    background: black;
    overflow: hidden;
}

.image-wrapper::before {
    background: #ff0013;
    transition: transform 0.5s, box-shadow 0.5s;
    transform: scale(1);
    transform-origin: center;
    z-index: -1;
}

.image-wrapper:hover::before {
    box-shadow: 0 0 25px #ff0013;
}

.userchat {
    color: #fff;
    background: #393939ad;
    border-radius: 10px 0px 10px 10px
}

.aichat {
    background: #d1c7c8c7;
    border-radius: 0px 10px 10px 10px;
}

.aichatimage {
    background-image: radial-gradient(circle, rgba(121, 118, 118, 0.726) 0%, rgba(255, 255, 255, 0) 100%);
    background-size: 100px 100px;
    animation: pulse 2s infinite;
    border-radius: 0px 10px 10px 10px;
  }
  
  @keyframes pulse {
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 1.2; /* Fully visible again */
    }
  }



.image-wrapper:hover .image {
    box-shadow: 0 0 25px #ff0013;
}

.chatnames {
    height: 45rem;
    overflow: scroll;
    scrollbar-width: none;
}

.hover-button {
    transition: transform 0.5s, box-shadow 0.5s;
}

.hover-button-wrapper::before {
    background: #ff0013;
    transition: transform 0.5s, box-shadow 0.5s;
    transform-origin: center;
    z-index: -1;
}

.hover-button-wrapper:hover::before {
    box-shadow: 0 0 25px #ff0013;
}

.hover-button-wrapper:hover .hover-button {
    box-shadow: 0 0 25px #ff0013;
}

.no-scrollbar {
    overflow: hidden;
    height: 100vh;
}

/* @font-face {
    font-family: "Josefin Sans";
    src: url("./assets/font/JosefinSans/JosefinSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Kanit";
    src: url("./assets/font/Kanit/Kanit-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "KaushanScript";
    src: url("./assets/font/Kaushan_Script/KaushanScript-Regular.ttf") format("truetype");
} */

.hero {
    color: white;
    -webkit-text-fill-color: black;
    -webkit-text-stroke: 1px white;
    font-weight: 800;
}

.react-multiple-carousel__arrow {
    background-color: #ff0013 !important;
}

.react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
}

.react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
}

.aftersexBg {
    background: url("../src/assets/img/afterModel.png");
    background-repeat: no-repeat;
    background-size: cover !important;
}

.pLineHeight p {
    line-height: 35px !important;
}

.loader {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    -webkit-mask: conic-gradient(from 15deg, #0000, #000);
    animation: l26 1s infinite steps(12);
}

.loader,
.loader:before,
.loader:after {
    background:
        radial-gradient(closest-side at 50% 12.5%, #f03355 96%, #0000) 50% 0 / 20% 80% repeat-y,
        radial-gradient(closest-side at 12.5% 50%, #f03355 96%, #0000) 0 50% / 80% 20% repeat-x;
}

.loader:before,
.loader:after {
    content: "";
    grid-area: 1 / 1;
    transform: rotate(30deg);
}

.loader:after {
    transform: rotate(60deg);
}

@keyframes l26 {
    100% {
        transform: rotate(1turn);
    }
}



/* sidebar */

.sidebar button:hover {
    background-color: #FD818A;
    border-radius: 0 20px 20px 0;
}


.sidebarmob {
    position: fixed;
    overflow: hidden;
    top: 0px;
    left: 0px;
    bottom: 97px;
    width: 0px;
    border-right: 1px solid #ccc;
    box-shadow: 4px 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    background: #ffffff;
    will-change: width;
    transition: width 0.4s;
    z-index: 1100;
}

.sidewidth {
    width: 270px !important;
}

.sidewidthmob {
    width: 60% !important;
}

.sidebar:hover {
    width: 270px;
}

.inner {
    position: absolute;
    top: 20px;
    left: 0;
    bottom: 0;
    width: 270px;
}

.header {
    display: flex;
    align-items: center;
    height: 82px;
    padding: 0 20px;
    background:
        rgb(000/ 15%);
}

.header h1 {
    margin-left: 12px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 2px;
}

.menu {
    position: relative;
    display: grid;
}

.menu::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 56px;
    width: 6px;
    background: #ff0303;
    translate: 0 var(--top);
    transition: 0.5s;
}

.menu button {
    display: flex;
    gap: 20px;
    align-items: center;
    height: 46px;
    width: 100%;
    padding: 0 22px;
    color: rgb(255 255 255 / 60%);
    transition: 0.5s;
}

.sidebar {
    position: fixed;
    overflow: hidden;
    top: 72px;
    left: 0px;
    bottom: 0px;
    width: 58px;
    border-right: 1px solid #ccc;
    box-shadow: 4px 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    background: #ffffff;
    will-change: width;
    transition: width 0.4s;
    z-index: 1000;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.sidebar::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Webkit-based browsers */
}

.sidebar {
    -ms-overflow-style: none;  /* Hide scrollbar in IE/Edge */
    scrollbar-width: none;      /* Hide scrollbar in Firefox */
}

/* Styles for above 1300px */
@media (min-width: 1301px) {
    .menu button {
        display: flex;
        gap: 20px;
        align-items: center;
        height: 56px;
        width: 100%;
        padding: 0 22px;
        color: rgb(255 255 255 / 60%);
        transition: 0.5s;
    }

    .sidebar {
        position: fixed;
        overflow: hidden;
        top: 80px;
        left: 0px;
        bottom: 0px;
        width: 62px;
        border-right: 1px solid #ccc;
        box-shadow: 4px 0 8px rgba(0, 0, 0, 0.2);
        border-radius: 0px;
        background: #ffffff;
        will-change: width;
        transition: width 0.4s;
        z-index: 1000;
    }
}

.menu button:hover>span {
    opacity: 1;
}

.menu:is(button:hover, .active) {
    background: rgb(000 / 35%);
    color: rgb(255 255 255 / 100%);
    opacity: 1;
}

.menu button p,
header h1 {
    opacity: 03;
    transition: 0.5s;
}

.menu button>img {
    width: 24px;
    height: 24px;
}

.sidebar:hover :is(.menu button p, header h1) {
    opacity: 1;
}

.sidebar:hover .menu .active {
    cursor: default;
}

.sidebar:hover .logo {
    scale: 1;
}

.sidebar:hover .menu button:hover:not(.active) {
    background: rgb(000/ 8%);
}

/* sidebar */

.custom-loader span {
    position: absolute;
    top: 140px;
    left: 52px;
}

#outerLoaders,
#outerCustomLoader {
    align-items: center;
    background-color: #fff3;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
}
#outerLoaders,
#outerCustomLoaderReqtime {
    align-items: center;
    background-color: #fff3;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
}

.custom-loader {
    width: 200px;
    /* / Increase the width / */
    aspect-ratio: 1;
    /* / Keep the aspect ratio / */
    color: #dc1818;
    background:url('/src/assets/img/Logo_Icon.png');
    background-position: center;
    background-size: 50% 50%;
    /* / Adjust background size if needed / */
    background-repeat: no-repeat;
    position: absolute;
    z-index: 999;
}

.custom-loader:after {
    content: "";
    position: absolute;
    inset: 0;
    background: inherit;
    opacity: 0.4;
    animation: l3 1s infinite;
}

@keyframes l3 {
    to {
        transform: scale(1.8);
        opacity: 0;
    }
}

/* .chatinput{
    position: absolute;
    width: 100%;
    bottom: 0;
}

@media (max-width: 768px) {
    .chatinput {
      bottom: 50px !important;
    }
  } */


  #responsive-text-loader {
    color: #f7f7f7;
    margin-top: 25% !important;
  }
  
  /* Adjust margin for smaller screens */
  @media screen and (max-width: 768px) {
    #responsive-text-loader {
      margin-top: 40% !important;
      text-align: center; /* Decrease margin for tablets */
    }
  }
  
  @media screen and (max-width: 480px) {
    #responsive-text-loader {
      margin-top: 50% !important;
      text-align: center; /* Decrease margin for mobile */
    }
  }
  
  @media screen and (max-width: 320px) {
    #responsive-text-loader {
      margin-top: 60% !important;
      text-align: center; /* Decrease margin further for very small screens */
    }
  }



.loaderdots {
    width: 60px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 100%;
    animation: l7 1s infinite linear;
}

@keyframes l7 {
    33% {
        background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%
    }

    50% {
        background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%
    }

    66% {
        background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%
    }
}



i.fa-solid.fa-circle-dot.blink {
    color: red;
}

@media (min-width: 768px) {

    .bottombar,
    .chatside {
        display: none;
    }

    .chatback {
        display: none;
    }

    .sidebarmob {
        display: none;
    }
}

@media (max-width: 768px) {

    .sidebar,
    .formob {
        display: none;
    }

    .chatbox_mob {
        position: absolute;
        z-index: 9999;
        background-color: white;
    }

    .chatback2 {
        display: none;
    }

    .text-sm {
        font-size: 12px;
        line-height: 1.25rem;
    }

    .w-16 {
        width: 3rem;
    }

    .chatside {
        display: block !important;
    }

    .sidebar,
    .formob,
    button.text-black.bg-transparent.border-none.cursor-pointer.ms-4.me-10 {
        display: none;
    }

    .ml-\[70px\] {
        margin-left: 0;
    }

    .grid.justify-center.p-4.mt-16.sm\:mt-20.lg\:mt-24.mr-4.sm\:mr-6.lg\:mr-10 {
        margin: 10px;
        font-size: 16px !important;
    }

    .text-3xl.sm\:text-4xl.lg\:text-5xl.font-medium.text-center {
        font-size: 24px !important;
    }
}

@media (min-width: 500px) {
    .formobile {
        display: none;
    }
}

@media (max-width: 500px) {

    .text-xl,
    button {
        font-size: 14px !important;
    }

    .custombuttonsize{
        font-size: 11px !important;
    }

    .grid.justify-center.p-4.mt-16.sm\:mt-20.lg\:mt-24.mr-4.sm\:mr-6.lg\:mr-10 {
        margin: 0 10px 0 0;
        font-size: 12px !important;
    }

    .text-3xl.sm\:text-4xl.lg\:text-5xl.font-medium.text-center {
        font-size: 14px !important;
    }

    .bg-gradient-to-r.from-red-600.h-20.px-10.to-white.w-full.flex.justify-between.items-center {
        display: none;
    }
}




.recordloader {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #ff0000;
    box-shadow: 0 0 0 0 #ffffff;
    animation: l3 1.5s infinite linear;
    position: relative;
}

.recordloader:before,
.recordloader:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 #0004;
    animation: inherit;
    animation-delay: -0.5s;
}

.recordloader:after {
    animation-delay: -1s;
}

@keyframes l2 {
    100% {
        box-shadow: 0 0 0 40px #0000
    }
}


.carousel-container {
    position: relative;
    overflow: hidden;
}

.backgroundStyle {
    background: 'linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover';
}

.carousel-item {
    position: absolute;
    animation: rotate 100s infinite;
}

.icon-pickupLiftIcon:before {
    content: ">";
}

.icon-pickuprightIcon:before {
    content: "<";
}

@keyframes rotate {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    20% {
        opacity: 1;
        transform: translateX(0);
    }

    80% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}

.google-signin {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    padding: 10px;
}

.animated-text {
    font-weight: bold;
    background: linear-gradient(265deg, #000000, #050001, #252121);
    background-size: 200% 200%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: colorShift 3s ease-in-out infinite;
}

@keyframes colorShift {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


@keyframes glow-background {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.8;
    }
}

@keyframes glow-border {
    0% {
        border-color: transparent;
    }

    100% {
        border-color: #99111f;
    }
}

@keyframes glow-icon {
    0% {
        filter: brightness(1);
    }

    100% {
        filter: brightness(1.5);
    }
}

.animate-glow-background {
    animation: glow-background 0.3s ease-in-out forwards;
}

.animate-glow-border {
    animation: glow-border 0.3s ease-in-out forwards;
}

.glow-icon {
    animation: glow-icon 0.3s ease-in-out forwards;
}

.loaderaud-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* The actual loader - a circular spinning loader */
  .loaderaud {
    border: 6px solid #ece7e7; /* Light grey border */
    border-top: 6px solid #921111; /* Blue color for the spinning effect */
    border-radius: 50%; /* Makes the loader circular */
    width: 40px;
    height: 40px;
    animation: spinaud 2s linear infinite; /* Animation for continuous rotation */
  }
  
  /* Animation for spinning */
  @keyframes spinaud {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .audio-wave-ui {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 25px;
    background-color: #333;  /* Dark background for the wave container */
    border-radius: 20px;  /* Rounded corners */
    padding: 10px;
    margin-top: 10px;
  }
  
  .wave-container {
    display: flex;
    justify-content: space-between;
    width: 60%;
    /* max-width: 500px; */
    height: 20px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.95);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .animate-fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .wave {
    width: 8px;
    margin-top: 4px;
    height: 60%;
    background-color: #d6c9c7;  /* Wave color */
    border-radius: 10px;
    animation: waveAnimation 1.5s ease-in-out infinite;
  }
  
  .wave:nth-child(odd) {
    animation-duration: 1.2s;  /* Different animation durations for variation */
  }
  
  .wave:nth-child(even) {
    animation-duration: 1.5s;
  }
  
  @keyframes waveAnimation {
    0% {
      transform: scaleY(0.6);
    }
    50% {
      transform: scaleY(1.2);
    }
    100% {
      transform: scaleY(0.6);
    }
  }
  
  .image-loader {
    padding: 12.5px 0;
    position: absolute;
    top: 46.5%;
    left: 46.5%;
    -webkit-filter: url("#goo");
    filter: url("#goo");
  }
  
  .image-loader .rect {
    position: relative;
    height: 80px;
    width: 80px;
    animation: rotating 1.7s linear infinite;
  }
  
  @keyframes rotating {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  .image-loader .rect .dot {
    position: absolute;
    left: 28px;
    top: 28px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background-color: #FFF;
  }
  
  .image-loader .rect .dot.d_i {
    animation: dot_i 1.5s ease-in-out infinite;
  }
  
  .image-loader .rect .dot.d_ii {
    animation: dot_ii 1.5s ease-in-out infinite;
  }
  
  @keyframes dot_i {
    0%, 100% {
      transform: translateY(0) scale(1);
    }
    50% {
      transform: translateY(20px) scale(0.8);
    }
  }
  
  @keyframes dot_ii {
    0%, 100% {
      transform: translateY(0) scale(1);
    }
    50% {
      transform: translateY(-20px) scale(0.8);
    }
  }

input:focus:active ,textarea:focus:active {
    /* This is redundant since both states are handled by the previous rule, but you can leave it for clarity */
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus{
    border-color: #990022;
    --tw-ring-shadow: none;
}

.headerhover:hover{
    filter: drop-shadow(3px 4px 6px red);
}

.loader-chat {
    width: 85px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #ff4040;
    animation:
      l20-1 0.8s infinite linear alternate,
      l20-2 1.6s infinite linear;
  }
  @keyframes l20-1{
     0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
     12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
     25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
     50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
     62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
     75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
     100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
  }
  @keyframes l20-2{ 
    0%    {transform:scaleY(1)  rotate(0deg)}
    49.99%{transform:scaleY(1)  rotate(135deg)}
    50%   {transform:scaleY(-1) rotate(0deg)}
    100%  {transform:scaleY(-1) rotate(-135deg)}
  }

  @keyframes recordingAnimation {
    0%, 100% {
      transform: scale(1);
      opacity: 1;
      background-color: #FF0000; 
    }
    50% {
      transform: scale(1.1);
      opacity: 0.7;
      background-color: #FF4D4D; 
    }
  }
  
  .recordingChatMsg {
    width: 2rem; 
    height: 2rem;
    border-radius: 50%;
    animation: recordingAnimation 1.5s ease-in-out infinite;
  }

  .my-masonry-grid {
    display: flex;
    margin-left: -16px; /* Adjust based on your desired gutter size */
    width: auto;
  }
  
  .my-masonry-grid_column {
    padding-left: 16px; /* Same as the negative margin */
    background-clip: padding-box;
  }
  
  /* Optional: Add spacing between items */
  .my-masonry-grid_column > div {
    margin-bottom: 16px;
  }
  
  .video-js.vjs-download .vjs-button.vjs-download .vjs-icon-placeholder {
    display: block;
    margin: 0 auto;
    width: 11px;
    height: 11px;
    clip-path: polygon(50% 100%, 2% 46%, 32% 46%, 32% 0%, 67.6% 0%, 67.6% 46%, 98% 46%, 50% 100%);
    background-color: #fff
  }