.container {
    max-width: 100rem; /* Reduced from 124rem */
    padding: 3rem 1rem; /* Reduced from 4rem */
    margin: 0 auto;
}
  
.heading {
    padding: 0.8rem 0; /* Reduced from 1rem */
    font-size: 2.5rem; /* Reduced from 3.5rem */
    text-align: center;
}
  
.swiper_container {
    height: 40rem; /* Reduced from 52rem */
    padding: 1rem 0; /* Reduced from 2rem */
    position: relative;
}
  
.swiper-slide {
    width: 28rem; /* Reduced from 34rem */
    height: 35rem; /* Reduced from 40rem */
    position: relative;
}
  
@media (max-width: 500px) {
    .swiper_container {
        height: 37rem; /* Reduced from 47rem */
    }
    .swiper-slide {
        width: 22rem !important; /* Reduced from 26rem */
        height: 30rem !important; /* Reduced from 34rem */
    }
    .swiper-slide img {
        width: 22rem !important; /* Reduced from 26rem */
        height: 30rem !important; /* Reduced from 34rem */
    }
}
  
.swiper-slide img {
    width: 28rem; /* Reduced from 34rem */
    height: 35rem; /* Reduced from 40rem */
    border-radius: 1.5rem; /* Reduced from 2rem */
    object-fit: cover;
}
  
.slider-controler {
    position: relative;
    bottom: 1.5rem; /* Reduced from 2rem */
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.slider-controler .swiper-button-next {
    left: 56% !important; /* Reduced from 58% */
    transform: translateX(-50%) !important; /* Reduced from -58% */
}
.swiper-button-prev:after, .swiper-button-next:after {
    font-size: 24px !important;
}
  
@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 60% !important; /* Reduced from 70% */
        transform: translateX(-60%) !important; /* Reduced from -70% */
    }
}
  
@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 75% !important; /* Reduced from 80% */
        transform: translateX(-75%) !important; /* Reduced from -80% */
    }
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active{
    background: #b30016;
}
  
.slider-controler .slider-arrow {
    color: #b3000c;
    background: var(--white);
    width: 3rem; /* Reduced from 3.5rem */
    height: 2rem; /* Reduced from 3.5rem */
    border-radius: 50%;
    left: 44%; /* Reduced from 42% */
    transform: translateX(-40%);
    filter: drop-shadow(0px 6px 20px rgba(18, 28, 53, 0.1)); /* Reduced shadow */
}
@media (max-width: 450px) {
    .slider-controler .slider-arrow {
        left: 25%; /* Reduced from 42% */
    }
}
.slider-controler .slider-arrow ion-icon {
    font-size: 0.5rem; /* Reduced from 2rem */
    color: #b3000c;
}
  
.swiper-pagination {
    position: relative;
    width: 12rem !important; /* Reduced from 15rem */
    bottom: 0.5rem; /* Reduced from 1rem */
}
  
.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 6px 20px rgba(18, 28, 53, 0.1)); /* Reduced shadow */
}
  
.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
}
.slider-arrow {
    /* position: relative; */
  }
  .prev-text {
    margin-right: 22px;
}
  .next-text {
    margin-left: 82px;
}
  
  .hover-text {
    opacity: 0;
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #333;
    pointer-events: none; /* To ensure the text doesn't interfere with clicking */
    transition: opacity 0.3s ease;
  }
  
  .slider-arrow:hover .hover-text {
    opacity: 1;
  }